<template>
  <div class="api-key-management childpool-wrap">
    <input id="cpy" type="hidden" />
    <CModal id="createModal" :show.sync="createKeyModal" size="sm" :centered="true">
      <template #header>
        <CButtonClose class="close-modal" @click="createKeyModal = false" />
      </template>
      <div>
        <div class="pb-1">Name</div>
        <CInput @keyup.enter="createKey" v-model="apiName" placeholder="Enter name" />
      </div>
      <template #footer>
        <CButton @click="createKey" color="primary">Create Key</CButton>
      </template>
    </CModal>
    <CModal id="deleteModal" :show.sync="warningModal" :centered="true">
      <template #header>
        <CButtonClose class="close-modal" @click="warningModal = false" />
      </template>
      <div>
        <div class="pb-4 tit-dele">
          <h4>Delete account?</h4>
        </div>
        <div class="text-modal">
          Are you sure you want to delete key
          <strong>{{nameDelete}}</strong>?
        </div>
      </div>
      <template #footer>
        <CButton
          class="cancel-btn"
          @click="warningModal = false"
          color="dark"
          variant="outline"
        >Cancel</CButton>
        <CButton @click="deleteKey" class="delete-btn">Delete</CButton>
      </template>
    </CModal>
    <CCard>
      <CCardBody>
        <div class="text-right mb-2">
          <CButton color="primary" @click="createKeyModal = true" v-if="this.hasAdd === true">
            <span class="pr-1">
              <CIcon :content="cilMedicalCross" />
            </span>
            Generate New Key
          </CButton>
        </div>
        <CDataTable
          class="mb-0"
          hover
          outlined
          striped
          :items="apiKeys"
          :fields="tableHeaders"
          head-color="light"
          no-sorting
        >
          <td slot="order" class="text-center" slot-scope="{ item, index }">{{ index + 1 }}</td>
          <td slot="apiKey" slot-scope="{ item }">
            <span>{{ item.api_key }}</span>
            <span class="pl-4" @click="copy(item.api_key)">
              <CIcon :content="cilCopy" />
            </span>
          </td>
          <td slot="secretKey" slot-scope="{ item }">
            <span class="secret">*****</span>
            <span class="pl-4" @click="copy(item.secret_key)">
              <CIcon :content="cilCopy" />
            </span>
          </td>
          <td slot="status" slot-scope="{ item }">
            <span class="status-icon">
              <i :class="item.status=='CONNECTED'?'far fa-check-circle':'far fa-times-circle'"></i>
            </span>
          </td>
          <td class="text-center" slot="action" slot-scope="{ item }" v-if="this.hasDel === true">
            <span @click="openDeleteModal(item, item.name)">
              <CIcon width="24px" class="deleteIcon" :content="cilTrash" />
            </span>
          </td>
        </CDataTable>
        <div>
          <CPagination
            v-if="pages>1"
            :activePage.sync="activePage"
            :pages="pages"
            align="end"
            @update:activePage="pushPage"
          />
        </div>
      </CCardBody>
    </CCard>
  </div>
</template>
<script>
import { cilMedicalCross, cilCopy, cilTrash } from '@coreui/icons';
import endpoints from '@/constants/endpoints';
import responseHelper from '@/utils/response-helper';
import { mapState } from 'vuex';
export default {
  name: 'ApiKeyManagement',
  data() {
    return {
      cilMedicalCross,
      cilCopy,
      cilTrash,
      warningModal: false,
      createKeyModal: false,
      apiName: '',
      tableHeaders: [
        { key: 'order', label: '#', _classes: 'text-center' },
        { key: 'name', label: 'Name' },
        { key: 'apiKey', label: 'API Key' },
        { key: 'secretKey', label: 'Secret Key' },
        { key: 'status', label: 'Status' },
        { key: 'action', label: 'Action', _classes: 'text-center' },
      ],
      apiKeys: [],
      activePage: 1,
      partnerID: '',
      limit: 5,
      pages: 0,
      selectedKey: '',
      selectedItem: null,
      nameDelete: '',
      hasAdd: false,
      hasDel: false,
    };
  },
  computed: {
    ...mapState({
      userPermission: state => state.auth.userPermissions,
    }),
    offset() {
      return (this.activePage - 1) * this.limit;
    },
  },
  async mounted() {
    this.partnerID = this.$route.params.id;
    const apiKeys = await this.getListAPIKeys();
    if (!apiKeys) {
      return;
    }

    this.apiKeys = apiKeys.items.slice();
    const totalItems = parseInt(apiKeys.total);
    this.pages = parseInt(Math.ceil(totalItems / this.limit));
    await this.checkPermission();
  },
  methods: {
    async getListAPIKeys() {
      try {
        const params = {
          limit: this.limit,
          offset: this.offset,
        };
        const result = await this.$http.get(endpoints.getAPIKeys(this.partnerID), {
          params: params,
        });

        return result.data;
      } catch (err) {
        return null;
      }
    },

    async createKey() {
      this.createKeyModal = false;
      if (!this.apiName) {
        return;
      }

      try {
        const data = {
          name: this.apiName,
        };
        const res = await this.$http.post(endpoints.createAPIKey(this.partnerID), data, {
          headers: {
            'Content-Type': 'application/json',
          },
        });

        this.apiName = '';
        this.pushPage();
        this.$notify({
          group: 'notifications',
          type: 'success',
          title: 'Success',
          text: 'Your api has created',
          duration: 1000,
        });
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: 'Error',
          text: responseHelper.getErrorMessage(err),
          duration: 1000,
        });
      }
    },

    openDeleteModal(item, name) {
      this.warningModal = true;
      this.selectedItem = item;
      this.nameDelete = name;
    },

    async deleteKey() {
      this.warningModal = false;
      try {
        const result = await this.$http.delete(endpoints.revokeAPIKey(this.partnerID, this.selectedItem.api_key));

        this.pushPage();
        this.$notify({
          group: 'notifications',
          type: 'success',
          title: 'Success',
          text: 'Your api has been deleted',
          duration: 1000,
        });
        // call list
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: 'Error',
          text: responseHelper.getErrorMessage(err),
          duration: 1000,
        });
      }
    },

    async pushPage() {
      const apis = await this.getListAPIKeys();
      if (!apis) {
        return;
      }
      const totalItems = parseInt(apis.total);
      this.pages = parseInt(Math.ceil(totalItems / this.limit));
      this.apiKeys = apis.items.slice();
    },

    copy(item) {
      const element = document.querySelector('#cpy');
      element.setAttribute('type', 'text');
      element.value = item;
      element.select();
      try {
        const successful = document.execCommand('copy');
        const msg = successful ? 'Copied' : 'Oops, unable to copy';
        this.$notify({
          group: 'notifications',
          type: 'success',
          title: 'Success',
          text: 'Copied',
        });
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: 'Error',
          text: 'Oops, unable to copy',
        });
      }
      element.setAttribute('type', 'hidden');
      window.getSelection().removeAllRanges();
    },
    checkPermission() {
      if (this.userPermission.length > 0) {
        this.userPermission.forEach(items => {
          if (items.name === 'CREATE_KEY_PARTNER') {
            this.hasAdd = true;
          }
          if (items.name === 'DELETE_KEY_PARTNER') {
            this.hasDel = true;
          }
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.api-key-management /deep/ {
  // $green: #4dc1a1;
  $red: red;
  // background-color: transparent;
  .secret {
    user-select: none;
  }
  .modal-header {
    border: none;
    padding: 0 8px;
  }

  .modal-footer {
    border: none;
    justify-content: center;
  }

  .close-modal {
    position: absolute;
    right: 8px;
    top: 4px;
    z-index: 1;
  }

  #createModal {
    .form-group {
      margin-bottom: 0;
    }
  }

  #deleteModal {
    .text-modal {
      font-size: 18px;
    }
  }
  .deleteIcon {
    fill: #ff0000;
  }
  .delete-btn {
    background-color: $red;
    color: #ffffff;
  }
  .cancel-btn {
    padding-left: 24px;
    padding-right: 24px;
  }
  .c-icon {
    cursor: pointer;
  }
  .status-icon {
    margin-left: 10px;
    i {
      font-size: 24px;
    }
    .fa-check-circle {
      color: #078c4f;
    }
    .fa-times-circle {
      color: #d31427;
    }
  }
  .modal-content {
    width: 85%;
    .modal-header {
      border: none;
      padding: 0 8px;
      display: none;
    }
    .text-modal {
      strong {
        color: #3a4a7f;
        font-weight: 500;
      }
    }
    .modal-footer {
      border: none;
      justify-content: center;
      .delete-btn {
        background-color: #ff5555;
        color: #ffffff;
        width: 150px;
        font-weight: 500;
        font-size: 14px;
        margin: 0 10px;
      }
      .cancel-btn {
        width: 150px;
        border: 2px solid #cbcbcb;
        color: #657187;
        font-weight: 500;
        font-size: 14px;
        margin: 0 10px;
        &:hover {
          background-color: #fff;
        }
      }
    }
  }
}
</style>
